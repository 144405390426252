'use client';

import { FAQItem } from 'components/FAQItem';
import useViewport from 'hooks/useViewport';
import { type Faq } from 'gql/queries/generated';

import { faqData } from './utils';

interface FAQProps {
  items?: Faq[];
}
const FAQ = ({ items }: FAQProps) => {
  const { isMobile, isTablet } = useViewport();

  const FAQList = items?.length ? items : faqData;

  const halfIndex = Math.ceil(FAQList.length / 2);

  if (!isMobile && !isTablet) {
    return (
      <section
        className="mx-auto mb-9 mt-16 flex max-w-4xl flex-col justify-center px-5 md:px-12 lg:px-32 xl:px-16 2xl:px-6"
        id="faq"
      >
        <h2 className="text-2xl font-medium">FAQS:</h2>
        <div className="mt-[1.25rem] " />
        <ol className="flex list-inside list-decimal gap-14 lg:gap-[117px]">
          <div className="flex basis-1/2 flex-col border-t-[.0625rem] border-black-500 md:max-w-[21rem]">
            {FAQList.slice(0, halfIndex).map(({ question, answer }, index) => {
              if (!question || !answer) return null;

              return (
                <FAQItem
                  key={question}
                  answer={answer}
                  id={index === 3 ? 'faq-item-4' : undefined}
                  question={question}
                />
              );
            })}
          </div>
          <div className="flex basis-1/2 flex-col border-t-[.0625rem] border-black-500 md:max-w-[21rem]">
            {FAQList.slice(halfIndex).map(({ question, answer }) => {
              if (!question || !answer) return null;

              return <FAQItem key={question} answer={answer} question={question} />;
            })}
          </div>
        </ol>
      </section>
    );
  }

  return (
    <section className="mb-9 mt-16 px-5" id="faq">
      <h2 className="text-2xl font-medium">FAQS:</h2>
      <div className="mt-[1.25rem] border-t-[.0625rem] border-black-500" />
      <ol className="flex list-inside list-decimal flex-col">
        {FAQList.map(({ question, answer }, index) => {
          if (!question || !answer) return null;

          return (
            <FAQItem
              key={question}
              answer={answer}
              id={index === 3 ? 'faq-item-4' : undefined}
              question={question}
            />
          );
        })}
      </ol>
    </section>
  );
};

export default FAQ;
