import Link from 'next/link';

interface GreenCTALinkProps {
  buttonLink?: string;
  buttonText?: string;
}

const GreenCTALink = ({ buttonLink, buttonText }: GreenCTALinkProps) => {
  return (
    <Link
      className="mx-auto rounded bg-green-800 px-4 py-2 text-center text-xs font-semibold uppercase tracking-widest text-white shadow-lg hover:bg-green-600 active:bg-green-900"
      href={buttonLink || ''}
      target="_blank"
    >
      {buttonText}
    </Link>
  );
};

export default GreenCTALink;
