'use client';
import { useState, type FC } from 'react';
import Image from 'next/image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Arrow } from 'assets/icons';
import getDocumentOptions from 'lib/getDocumentOptions';
import { type FaqAnswer } from 'gql/queries/generated';

interface FAQItemProps {
  id?: string;
  question: string;
  answer: string | FaqAnswer;
}

const FAQItem: FC<FAQItemProps> = ({ question, answer, id }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="flex flex-col border-b-[.0625rem] border-black-500 py-[1.25rem]">
      <div
        className="flex cursor-pointer items-center gap-[.6094rem]"
        id={id}
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
      >
        <Image
          alt={!isExpanded ? 'Expand question item icon' : 'Collapse question item icon'}
          className={`${
            isExpanded ? 'rotate-180' : ''
          } h-[9px] w-[16.5px] transition-all duration-300 ease-in-out`}
          src={Arrow}
        />
        <li className="font-semibold text-black-800">
          <span>{question}</span>
        </li>
      </div>
      {isExpanded && (
        <div className="mb-[.4375rem] mt-4 max-w-[90%] pl-[1.6rem]">
          {typeof answer === 'string' ? (
            <p>{answer}</p>
          ) : (
            documentToReactComponents(answer.json, getDocumentOptions())
          )}
        </div>
      )}
    </div>
  );
};

export default FAQItem;
