export const faqData = [
  {
    question: 'What kinds of providers are available?',
    answer: 'Board-certified NPs, MDs and DOs.',
  },
  {
    question: 'Do you accept insurance?',
    answer: 'We are cash pay only at this time.',
  },
  {
    question: 'What is the per visit cost?',
    answer: 'You will be charged $14.95 per visit. The cost of medication is not included.',
  },
  {
    question: 'Do you offer care in all 50 states?',
    answer: 'We do not offer care in AR, DC, DE, ID, KS, LA, MS, NM, RI and WV at this time.',
  },
  {
    question: 'What are the age restrictions?',
    answer:
      'Agile currently only treats patients ages 18+ with exception to our acne and rosacea treatments. They require patient age to be 13+.',
  },
  {
    question: 'What types of care do you not support?',
    answer:
      'We are not a fit for you if you seek care for emergency or life-threatening conditions, management of diagnosed chronic conditions, long-term prescription refills, ongoing mental health care or weight loss/management.',
  },
  {
    question: 'What next steps will come from my visit?',
    answer:
      'Your personalized treatment plan may include lab testing, short-term prescriptions, imaging referrals or recommended lifestyle changes.',
  },
  {
    question: 'What do I need to have ready when requesting treatment?',
    answer: 'Just your photo ID (license or passport) and 5-10 minutes to answer some questions.',
  },
  {
    question: 'Do I have to schedule a live appointment?',
    answer:
      'No appointments or video calls required. A provider will review your medical intake, then you will be notified of the results and next steps for treatment.',
  },
  {
    question: 'Will my information be secure?',
    answer: 'Yes, our platform is encrypted and HIPAA compliant.',
  },
];
